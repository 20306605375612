const API_TOKEN = "b8b7cdf8fee5b963d376fb58b34eb00d9791f45b";

export async function getOrCreateOrganization(companyName) {
  try {
    const searchResponse = await fetch(`https://api.pipedrive.com/v1/organizations/search?term=${encodeURIComponent(companyName)}&api_token=${API_TOKEN}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const searchData = await searchResponse.json();

    if (searchData && searchData.data && searchData.data.items.length > 0) {
      return searchData.data.items[0].item.id;
    }

    const createResponse = await fetch(`https://api.pipedrive.com/v1/organizations?api_token=${API_TOKEN}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: companyName,
      }),
    });

    const createData = await createResponse.json();

    if (createData && createData.data) {
      return createData.data.id;
    } else {
      console.error("Failed to create organization:", createData);
      return null;
    }
  } catch (error) {
    console.error("Error fetching or creating organization:", error);
    return null;
  }
}

export async function createPersonInPipedrive(name, email) {
  try {
    const response = await fetch(`https://api.pipedrive.com/v1/persons?api_token=${API_TOKEN}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: [{ value: email, primary: true }],
      }),
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      console.error("Failed to create person in Pipedrive:", errorDetails);
      return null;
    } else {
      const data = await response.json();
      return data.data.id;
    }
  } catch (error) {
    console.error("Error creating person in Pipedrive:", error);
    return null;
  }
}

export async function submitLeadToPipedrive(title, personId, name) {
  try {
    const labelIds = ["9f58dc80-9691-11ef-a107-33a32deb33ed", "28038c00-a188-11ef-8f4e-9d5f3ec8a8d7"];
    const channel = 262;

    const response = await fetch(`https://api.pipedrive.com/v1/leads?api_token=${API_TOKEN}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: name,
        person_id: personId,
        channel_id: title,
        label_ids: labelIds,
        channel: channel,
      }),
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      console.error("Failed to add lead to Pipedrive:", errorDetails);
      return null;
    } else {
      const data = await response.json();
      console.log("Lead added successfully:", data);
      return data.data.id;
    }
  } catch (error) {
    console.error("Error submitting lead to Pipedrive:", error);
    return null;
  }
}
